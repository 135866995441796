export default {
    "common.menuInterface": "ចំណុចប្រទាក់ម៉ឺនុយ",
    "common.edit": "កែប្រែ",
    "common.enable": "បើកប្រើ",
    "common.disable": "បិទប្រើ",
    "common.displayOnMarquee": "បង្ហាញលើកត្រា",
    "common.removeOnMarquee": "យកចេញពីកត្រា",
    "common.displayOnPopup": "បង្ហាញលើប្លុកបើក",
    "common.removeOnPopup": "យកចេញពីប្លុកបើក",
    "common.interfaceList": "បញ្ជីចំណុចប្រទាក់",
    "common.assignMenu": "ចាត់តាំងម៉ឺនុយ",
    "common.newMenu": "ម៉ឺនុយថ្មី",
    "common.newRole": "តួនាទីថ្មី",
    "common.authorizationRole": "ការអនុញ្ញាត",
    "common.newUser": "អ្នកប្រើថ្មី",
    "common.more": "ច្រើនទៀត",
    "common.changePassword": "ប្តូរលេខសម្ងាត់",
    "common.audit": "សម្រេចចិត្ត",
    "common.addNew": "បន្ថែមថ្មី",
    "common.manualRecharge": "ការបង្កើតឡើងវិញដោយដៃ",
    "common.MANUALY": "ដោយដៃ",
    "common.qpay": "QPAY",
    "common.goodsDetail": "ព័ត៌មានលំអិតផលិតផល",
    "common.viewDetail": "មើលលំអិត",
    "common.ban": "ហាមឃាត់",
    "common.unban": "ដោះហាមឃាត់",
    "common.viewOrderFinance": "មើលផលិតផលហិរញ្ញវត្ថុ",
    "common.viewOrderGoods": "មើលការកុម្មង់ផលិតផល",
    "common.normal": "ធម្មតា",
    "common.abnormal": "មិនធម្មតា",
    "common.auditing": "ការសម្រេចចិត្ត",
    "common.audited": "បានសម្រេចចិត្ត",
    "common.auditFailed": "បានបរាជ័យក្នុងការសម្រេចចិត្ត",
    "common.bannerOn": "បើក",
    "common.bannerOff": "បិទ",
    "common.on": "បើក",
    "common.off": "បិទ",
    "common.chooseCategory": "ជ្រើសរើសប្រភេទ",
    "common.bannerDelete": "លុប",
    "common.okText": "យល់ព្រម",
    "common.cancelText": "បោះបង់",
    "common.tip": "ការណែនាំ",
    "common.messageSuccess": "{{action}} ជោគជ័យ",
    "common.unbind": "ផ្ដាច់ខាត",
    "common.operation": "ប្រតិបត្តិការ",
    "common.created": "បានបង្កើត",
    "common.updated": "បានធ្វើបច្ចុប្បន្នភាព",
    "common.deleted": "បានលុប",
    "common.HOME": "ទំព័រដើម",
    "common.OTHER": "ផ្សេងៗ",
    "common.PROMOTION": "ផ្សព្វផ្សាយ",
    "common.WALLET": "កាបូបនិងសាច់ប្រាក់",
    "common.MEMBER": "សមាជិក",
    "common.EXTERNAL": "ក្រៅប្រទេស",
    "common.MERCHANT_APPLY": "ការដាក់ពាក្យសម្រាប់អ្នកជំនួយពាណិជ្ជកម្ម",
    "common.GOODS": "ផលិតផល",
    "common.MERCHANT": "អ្នកជំនួយពាណិជ្ជកម្ម",
    "common.delete": "លុប",
    "common.sort": "តម្រៀប",
    "common.PASS": "បញ្ជាក់",
    "common.REJECT": "បដិសេធ",
    "common.STOP": "ឈប់",
    "common.OPEN": "បើក",
    "common.muteComment": "បិទមតិ",
    "common.levelOne": "កម្រិតទី១",
    "common.levelTwo": "កម្រិតទី២",
    "common.levelThree": "កម្រិតទី៣",
    "common.memberRebateSetting":
        "ការកំណត់អត្រាប្រាក់វិញរបស់សមាជិកអ្នកជំនួយពាណិជ្ជកម្ម",
    "common.goodsRebateSetting":
        "ការកំណត់អត្រាប្រាក់វិញរបស់សមាជិកផលិតផលអ្នកជំនួយពាណិជ្ជកម្ម",
    "common.memberAgentLevelSetting":
        "ការកំណត់កម្រិតអ្នកជំនួយពាណិជ្ជកម្មសមាជិក",
    "common.addGrade": "បន្ថែមកម្រិត",
    "common.WAIT_PAY": "រង់ចាំការបង់ប្រាក់",
    "common.SUCCEED": "បានជោគជ័យ",
    "common.manage": "គ្រប់គ្រង",
    "common.directRewardSetting": "ការកំណត់ការប្រាក់រង្វាន់ផ្ទាល់ខ្លួន",
    "common.newUserRewardSettings": "ការកំណត់ការប្រាក់រង្វាន់អ្នកប្រើថ្មី",
    "common.purchaseMembershipRewardSettings":
        "ការកំណត់ការប្រាក់រង្វាន់ការទិញសមាជិក",
    "common.WAIT": "រង់ចាំ",
    "common.FAIL": "បរាជ័យ",
    "common.reject": "បដិសេធ",
    "common.pass": "បញ្ជាក់",
    "common.UNPUBLISHED": "មិនបានផ្សព្វផ្សាយ",
    "common.OFF": "បិទ",
    "common.ON": "បើក",
    "common.buyVirtualGoodsRebateSettings":
        "ការកំណត់អត្រាប្រាក់វិញរបស់ផលិតផលកុំព្យូទ័រ",
    "common.depositInterestRebateSettings":
        "ការកំណត់អត្រាប្រាក់វិញរបស់ការដាក់ប្រាក់",
    "common.INCOME": "ចំណូល",
    "common.EXPEND": "ចំណាយ",
    "common.REBATE": "ប្រាក់វិញរបស់សមាជិក",
    "common.SHOP_MENU": "ម៉ឺនុយហាង",
    "common.FINANCIAL_PRODUCT": "ផលិតផលហិរញ្ញវត្ថុ",
    "common.COMMUNITY": "សហគ្រិនភាព",
    "common.FINANCIAL_PRODUCT_REWARD": "ការប្រាក់រង្វាន់ផលិតផលហិរញ្ញវត្ថុ",
    "common.REBATE_COMMISSION": "ការប្រាក់វិញរបស់សមាជិកនិងកម្រិតប្រាក់ជើងសារ",
    "common.WITHDRAW": "ដកប្រាក់",
    "common.WITHDRAW_ROLLBACK": "ដកប្រាក់វិញ",
    "common.RECHARGE": "ការបង្កើតឡើងវិញ",
    "common.INTEREST": "ការប្រាក់",
    "common.INTEREST_CAPITAL": "ប្រាក់ជើងសារការប្រាក់",
    "common.INTEREST_COMMISSION": "ការប្រាក់ជើងសារការប្រាក់",
    "common.OPEN_SHOP": "បើកហាង",
    "common.CANCEL_SHOP": "បោះបង់ហាង",
    "common.SHOP_TRANSFER": "ការផ្ទេរហាង",
    "common.BUY_ADVERTISEMENT": "ការទិញប្រព័ន្ធព័ត៌មាន",
    "common.BUY_FINANCIAL": "ការទិញផលិតផលហិរញ្ញវត្ថុ",
    "common.BUY_GOODS": "ការទិញផលិតផល",
    "common.BUY_VIP": "ការទិញសមាជិកសម្រាប់ការប្រាក់រង្វាន់",
    "common.BUY_VIP_COMMISSION":
        "ការប្រាក់រង្វាន់សមាជិកសម្រាប់ការប្រាក់រង្វាន់",
    "common.COMMISSION_TRANSFER": "ការផ្ទេរប្រាក់រង្វាន់",
    "common.COMMISSION_TRANSFER_INCOME_SCORE":
        "ការផ្ទេរប្រាក់រង្វាន់ពីការចំណូលស្ថានភាព",
    "common.COMMISSION_TRANSFER_SCORE":
        "ការផ្ទេរប្រាក់រង្វាន់ទៅការប្រាក់ជើងសារ",
    "common.COMMISSION_TRANSFER_INCOME_BALANCE":
        "ការផ្ទេរប្រាក់រង្វាន់ពីការចំណូលសាន្តិភាព",
    "common.COMMISSION_TRANSFER_BALANCE":
        "ការផ្ទេរប្រាក់រង្វាន់ទៅការប្រាក់ជើងសារ",
    "common.BEAN_TASK_REWARD": "ការប្រាក់រង្វាន់ការបញ្ចប់ការងារ",
    "common.FUN_SPORT_REWARD": "COMPLETE FUNSPORT MISSION",
    "common.FUN_TASK_REWARD": "COMPLETE FUNTASK MISSION",
    "common.BEAN_TRANSFER": "BEAN TRANSFER",
    "common.BEAN_CLAIM_TASK": "USE BEAN JOIN TASK",
    "common.BEAN_CANCEL_TASK": "GET BEAN FROM CANCEL TASK",
    "common.SALE_GOODS": "SALE GOODS",
    "common.BUY_BEAN": "BUY BEAN",
    "common.OLD_SYSTEM_RETURN": "OLD SYSTEM RETURN",
    "common.SCORE_REWARD": "SCORE REWARD FROM BUY VIP",
    "common.SYSTEM_REWARD": "SYSTEM REWARD",
    "common.ORDER_GOODS_REFUND": "GOODS REFUND",
    "common.STOCK": "STOCK",
    "common.FUN_SPORT": "FUN SPORT",
    "common.FUNTASK": "FUN TASK",
    "common.ORDER_LIST": "ORDER LIST",
    "common.yes": "Yes",
    "common.no": "No",
    "common.banEndTime": "Disable end time",
    "common.GOODS_DETAIL": "GOODS DETAIL",
    "common.choose_flag": "Go to select a flag",
    "common.reset": "Reset",
    "common.submit": "Submit",
    "common.confirmed": "Confirmed",
    "common.HOME_POPUP": "HOME POPUP",
    "common.CAROUSELSHOP": "Shop Carousel",
    "common.CAROUSEL": "CAROUSEL",
    "common.HOME_BANNER": "HOME BANNER",
    "common.SETTING_BANNER": "SETTING BANNER",
    "common.KING_KONG": "KING KONG",
    "common.totalRegistration": "Total Registration",
    "common.totalUserBuyVIP": "Total User Buy VIP",
    "common.totalRechargeAmount": "Total Bank Recharge(₮)",
    "common.totalRechargeCoin": "(USDT)Total Recharge coin",
    "common.totalRechargeDong": "(USDT)Total Recharge(₮)",
    "common.totalBankWithdraw": "Total Bank Withdraw(₮)",
    "common.totalUSDTWithdrawCoin": "(USDT)Total Withdraw coin",
    "common.totalUSDTWithdrawDong": "(USDT)Total Withdraw(₮)",
    "common.SHOP_STATUS_PENDING": "PENDING",
    "common.SHOP_STATUS_FAIL": "FAIL",
    "common.SHOP_STATUS_UNPAID": "UNPAID",
    "common.SHOP_STATUS_CANCEL": "CANCEL",
    "common.adminManagement": "Admin Management",
    "common.banForever": "Ban forever",
    "common.ordinary": "Normal",
    "common.vip": "VIP",
    "common.statisticData": "Statistical Data",
    "common.logout": "Logout",
    "common.BALANCE": "Balance",
    "common.INCOME_BALANCE": "Income Balance",
    "common.SCORE": "Score",
    "common.INCOME_SCORE": "Income Score",
    "common.BEAN": "Bean",
    "common.clickHere": "Click here",
    "common.password": "Password",
    "common.confirmPassword": "Confirm Password",
    "common.successModify": "Successfully Modified",
    "common.assignRole": "Assigning Roles",
    "common.addRole": "Add Role",
    "common.refuse": "Refuse",
    "common.refund": "Refund",
    "common.goToRefund": "Go to refund",
    "common.remove": "Remove",
    "common.cancel": "Cancel",
    "common.viewSales": "View Sales",
    "common.viewProducts": "View Products",
    "common.updateDelivery": "Update delivery",
    "common.set_default": "Set Default",
    "common.buy_member_reward_as_score": "Purchase membership reward as score",
    "common.PAID": "PAID",
    "common.total_withdraw_success": "Total Withdraw Success",
    "common.total_withdraw_waiting": "Total Withdraw Waiting",
    "common.total_Recharge_success": "Total Recharge Success",
    "common.total_income": "Total Income",
    "common.total_expend": "Total Expend",
    "common.note_create_task_configuration":
        "To enable tasks, please provide a value in the respective fields. Leaving a field empty will result in the task being disabled.",
    "common.invite_friends": "Invite friends",
    "common.visit_store": "Visit store",
    "common.watch_video": "Watch video",
    "common.answer_question": "Answer question",
    "common.read_news": "Read news",
    "common.visit_flash_sale": "Visit flash sale",
    "common.promote_links": "Share with friends",
    "common.bubble": "Bubble release",
    "common.totalPeopleJoinTask": "Total people join task",
    "common.totalScoreUserGet": "Total score user get",
    "common.totalAmount": "Total amount",
    "common.export": "Export",
    "common.variation": "Variation",
    "common.update_image": "Update Image",
    "common.save": "Save",
    "common.add_parent_variation": "Add Parent Variation",
    "common.add_child_variation": "Add Child Variation",
    "common.add_variation": "Add Variation",
    "common.edit_variation": "Edit Variation",
    "common.edit_name": "Edit Name",
    "common.edit_value": "Edit Value",
    "common.approvalFailed": "Approval Failed!",
    "common.rejectWithdraw": "Reject Withdraw",
    "common.INVEST": "INVEST",
    "common.INVEST_FINISH": "INVEST FINISH",
    "common.INVEST_USER_FINISH": "INVEST USER FINISH",
    "common.DEPOSIT": "DEPOSIT",
    "common.INVEST_INCOME": "INVEST INCOME",
    "common.CLUB": "CLUB",
    "common.TEAM_SAME_LEVEL": "TEAM SAME LEVEL",
    "common.TEAM_DIFF_LEVEL": "TEAM DIFF LEVEL",
    "common.RECOMMEND_DIRECT": "RECOMMEND DIRECT",
    "common.RECOMMEND_INDIRECT": "RECOMMEND INDIRECT",
    "common.SIGN": "SIGN",
    "common.SHARE": "SHARE",
    "common.TASK": "TASK",
    "common.INCOME_TRANSFER": "INCOME TRANSFER",
    "common.BALANCE_TRANSFER": "BALANCE TRANSFER",
    "common.ACCOUNT_BALANCE": "BALANCE",
    "common.ACCOUNT_INCOME": "INCOME",
    "common.WITHDRAW_CHARGE": "WITHDRAW CHARGE",
    "common.WITHDRAW_CHARGE_ROLLBACK": "WITHDRAW CHARGE ROLLBACK",
    "common.TRAVEL": "TRAVEL",
    "common.SCORE_TRANSFER": "SCORE TRANSFER",
    "common.NO_PAY": "NO PAY",
    "common.PAY_SUCCESS": "PAY SUCCESS",
    "common.PAY_FAILED": "PAY FAILED",
    "common.bsc": "BSC",
    "common.BSC": "BSC",
    "common.trc": "TRC",
    "common.TRC": "TRC",
    "common.AUDITING": "AUDITING",
    "common.AUDIT_PASS": "AUDIT PASS",
    "common.AUDIT_NOT_PASS": "AUDIT NOT PASS",
    "common.PAYED": "PAID",
    "common.DEPOSIT_AUDITING": "AUDITING",
    "common.DEPOSIT_SUCCESS": "SUCCESS",
    "common.DEPOSIT_FAIL": "FAIL",
    "common.rejectCashDeposit": "Reject Cash Deposit",
    "common.confirmCashDeposit": "Confirm Cash Deposit",
    "common.accept": "Accept",
    "common.back": "Back",
    "common.setToFixedTopHomePageDiscovery":
        "Set To Fixed Top Home Page Discovery",
    "common.failToFixedTopHomePageDiscovery":
        "Failed to set as fixed on the top of the Discovery home page.",
    "common.updateBasic": "Update amount of like",
    "common.add": "បន្ថែម",
    "common.vod.videos.create": "បង្កើតវីដេអូ",
    "common.breakfast": "អាហារពេលព្រឹក",
    "common.lunch": "អាហារពេលល្ងាច",
    "common.dinner": "អាហារពេលល្ងាច",
    "common.midnightsnack": "អាហារពេលលិល",
    "common.selectUpTo5": "ជ្រើសរើសបានតែចូលចិត្តបួនបួន",
    "common.contact": "ទំនាក់ទំនង",
};
