/**
 * Login component for the travel admin application.
 * @param title - The title of the login form.
 * @param logo - The logo of the login form.
 * @param isAdmin - A boolean indicating whether the user is an admin or not.
 * @param numRefresh - A number used to refresh the captcha.
 * @param onFinish - A function that is called when the form is submitted.
 * @param captchaProps - The properties of the captcha component.
 * @returns A React component that renders a login form.
 */
import {LockOutlined, UserOutlined, MobileOutlined, CheckCircleOutlined} from '@ant-design/icons'
import {LoginForm, ProFormText, ProFormItem} from '@ant-design/pro-components'
import React, {useEffect, useState} from 'react'
import {Captcha, CaptchaProps} from './captcha'
import {useRouter} from '@qsh/base'

interface LoginProps {
  title: string,
  logo: string,
  isAdmin: boolean,
  numRefresh?: Number,
  onFinish: (values: any) => Promise<boolean | void>;
  captchaProps?: CaptchaProps;
}

export const Login = ({title, logo, isAdmin, onFinish, captchaProps, numRefresh}: LoginProps) => {
  const [number, setNumber] = useState(0)
  useEffect(() => {
    setNumber(number+1)
  }, [numRefresh])

  return (
    <div style={{
      background: 'url(https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png) no-repeat center',
      backgroundSize: '100% auto',
      height: '100vh'
    }}>
      <div style={{paddingTop: 100}}>
        <LoginForm
          logo={logo}
          title={title}
          subTitle={<div style={{height: 50}}></div>}
          onFinish={async (values) => {
            onFinish(values)
          }}
          submitter={{
            searchConfig: {
              submitText: 'Login'}
            }}
        >
          {isAdmin && (
            <>
              <ProFormText
                name="username"
                fieldProps={{
                  size: 'large',
                  prefix: <UserOutlined className={'prefixIcon'}/>
                }}
                placeholder={'用户名'}
                rules={[
                  {
                    required: true,
                    message: '请输入用户名!'
                  }
                ]}
              />
              <ProFormText.Password
                name="password"
                fieldProps={{
                  size: 'large',
                  prefix: <LockOutlined className={'prefixIcon'}/>
                }}
                placeholder={'密码'}
                rules={[
                  {
                    required: true,
                    message: '请输入密码！'
                  }
                ]}
              />
              <ProFormItem
                name="captcha"
                transform={value => ({...value})}
                rules={[
                  {
                    required: true,
                    validator: async (_, value) => {
                      if (!value?.verify_code || !value?.captcha_id) {
                        return Promise.reject('输入验证码!')
                      }
                    }
                  }
                ]}
              >
                <Captcha {...captchaProps} refresh={number}/>
              </ProFormItem>
            </>
          )
          }
        </LoginForm>
      </div>
    </div>
  )
}
