import React, {createContext, useContext} from "react"

// create context and wrap it with useContext hook
export const makeContext = <T, >(hookFunc: () => T): [React.FC<{ value?: T ,children: any}>, () => T] => {
  const ctx = createContext<T>({} as T)
  const Provider: React.FC<{ value?: T ,children: any }> = ({children, value}) => {
    if (value) {
      return React.createElement(ctx.Provider, {value}, children)
    }
    return React.createElement(ctx.Provider, {value: hookFunc()}, children)
  }
  return [Provider, () => useContext(ctx)]
}

// lit component
export const makeComponent = <T, >(hookFunc: () => T, component: React.FC<T>): [React.FC<T>,() => T] => {
  return [component,hookFunc]
}
