export default {
    "tableColumn.name": "Name",
    "tableColumn.name_en": "English Name",
    "tableColumn.name_vi": "Vietnam Name",
    "tableColumn.flag": "Flag",
    "tableColumn.menuPath": "Menu path",
    "tableColumn.icon": "Icon",
    "tableColumn.state": "State",
    "tableColumn.sort": "Sort",
    "tableColumn.publish_date": "Publish Date",
    "tableColumn.creationTime": "Created time",
    "tableColumn.changeTime": "Change time",
    "tableColumn.operation": "Operation",
    "tableColumn.interfacePath": "Interface path",
    "tableColumn.id": "ID",
    "tableColumn.username": "Username",
    "tableColumn.nickname": "Nickname",
    "tableColumn.remark": "Remark",
    "tableColumn.title": "Title",
    "tableColumn.EN": "English",
    "tableColumn.VN": "Vietnamese",
    "tableColumn.image": "Image",
    "tableColumn.url": "Url",
    "tableColumn.type": "Type",
    "tableColumn.description": "Description",
    "tableColumn.description_en": "English Description",
    "tableColumn.description_vi": "Vietnam Description",
    "tableColumn.interestRate": "Interest Rates",
    "tableColumn.maximumInvestAmount": "Maximum Invest Amount",
    "tableColumn.minimumInvestAmount": "Minimum Invest Amount",
    "tableColumn.tradeNo": "Trade No",
    "tableColumn.memberID": "Member ID",
    "tableColumn.memberName": "Member name",
    "tableColumn.userID": "User ID",
    "tableColumn.userInfo": "User Info",
    "tableColumn.parentInfo": "Parent Info",
    "tableColumn.startTime": "Start time",
    "tableColumn.endTime": "End time",
    "tableColumn.freezeID": "Freeze ID",
    "tableColumn.frozenAmount": "Frozen amount",
    "tableColumn.restFrozenAmount": "Rest Frozen Amount",
    "tableColumn.shopName": "Shop name",
    "tableColumn.ShopInfo": "Shop Info",
    "tableColumn.ShopOwner": "Shop Owner",
    "tableColumn.Buyer": "Buyer",
    "tableColumn.Receiver": "Receiver",
    "tableColumn.category": "Category",
    "tableColumn.price": "Price",
    "tableColumn.rebateAmount": "Rebate Amount",
    "tableColumn.stock": "Goods Stock",
    "tableColumn.max_buy_limit": "Maximum Purchase",
    "tableColumn.buyCount": "Buy count",
    "tableColumn.collectCount": "Collect count",
    "tableColumn.publistTime": "Publist time",
    "tableColumn.createdUser": "Created user",
    "tableColumn.recommend": "Recommend",
    "tableColumn.logo": "Logo",
    "tableColumn.address": "Address",
    "tableColumn.balance": "Balance",
    "tableColumn.totalBalance": "Total balance",
    "tableColumn.imageSizeRemark": "Image size remark",
    "tableColumn.count": "Count",
    "tableColumn.platform": "Platform",
    "tableColumn.forceUpdate": "Force update",
    "tableColumn.version": "Version",
    "tableColumn.member": "Member",
    "tableColumn.mobile": "Mobile",
    "tableColumn.inviteCode": "Invite code",
    "tableColumn.invitedCode": "Invited code",
    "tableColumn.parentMobile": "Parent mobile",
    "tableColumn.level": "Level",
    "tableColumn.rechargeAmount": "Recharge",
    "tableColumn.withdrawAmount": "Withdraw",
    "tableColumn.avatar": "Avatar",
    "tableColumn.buyGoodsCount": "Buy count",
    "tableColumn.registerTime": "Register time",
    "tableColumn.thumbnail": "Thumbnail",
    "tableColumn.goods_image": "Product Image",
    "tableColumn.position": "Position",
    "tableColumn.content": "Content",
    "tableColumn.parentCategory": "Parent category",
    "tableColumn.openMerchant": "Open merchant",
    "tableColumn.vipAmount": "Purchasing price",
    "tableColumn.coverPicture": "Cover picture",
    "tableColumn.cover": "Cover",
    "tableColumn.urlType": "Url type",
    "tableColumn.term": "Term",
    "tableColumn.productNum": "Product quantity",
    "tableColumn.productLimit": "Product Limit",
    "tableColumn.directRecommendCount": "Direct recommend count",
    "tableColumn.followCount": "Follower count",
    "tableColumn.onlineTime": "Online time",
    "tableColumn.failReason": "Reject reason",
    "tableColumn.publishTime": "Release time",
    "tableColumn.muteDay": "Mute day",
    "tableColumn.directPushNum": "Direct push number",
    "tableColumn.subDirectPushNum": "Sub direct push number",
    "tableColumn.directPushIncome": "Direct push income",
    "tableColumn.subDirectPushIncome": "Sub direct push income",
    "tableColumn.totalIncome": "Total income",
    "tableColumn.userLevel": "User level",
    "tableColumn.agentLevel": "Agent level",
    "tableColumn.rebateRate": "Rebate rate%",
    "tableColumn.levelOne": "Level one",
    "tableColumn.levelTwo": "Level two",
    "tableColumn.levelThree": "Level three",
    "tableColumn.terminal": "Terminal",
    "tableColumn.amount": "Amount",
    "tableColumn.financeProduct": "Finance product name",
    "tableColumn.color_code": "Color code",
    "tableColumn.creator": "Creator",
    "tableColumn.profitSettings": "Profit settings",
    "tableColumn.profitSettingsDescription":
        "(This value is the proportion of platform profit, for example, 40%. Users purchase 10000 yuan of goods, the platform profit is 4000 yuan, and the store profit is 60% 6000 yuan)",
    "tableColumn.profitRatioModify": "Profit sharing ratio of platform",
    "tableColumn.advertisementPriceDaily": "Daily price advertisement",
    "tableColumn.advertisementConfiguration": "Advertisement configuration",
    "tableColumn.applicationShopCondition": "Application shop condition",
    "tableColumn.applicationShopConditionDescription":
        "(Conditions that must be met when applying for a store)",
    "tableColumn.platformProfit": "Platform profit",
    "tableColumn.serviceCharge": "Service charge",
    "tableColumn.originPrice": "Origin price",
    "tableColumn.bankName": "Bank",
    "tableColumn.bankCard": "Bank card",
    "tableColumn.bankUser": "Bank user",
    "tableColumn.fee": "Fee",
    "tableColumn.feeRate": "Fee rate",
    "tableColumn.realAmount": "Real amount",
    "tableColumn.updater": "Updater",
    "tableColumn.abstract": "Abstract",
    "tableColumn.is_china": "Is a China Products",
    "tableColumn.usdtAddress": "USDT Address",
    "tableColumn.fundType": "Fund type",
    "tableColumn.amountType": "Amount type",
    "tableColumn.discountRate": "Discount Rate",
    "tableColumn.AmounDay": "Amount day",
    "tableColumn.consumeBean": "Consume fun beans",
    "tableColumn.rewardBean": "Reward fun beans",
    "tableColumn.dailyStep": "Daily steps required",
    "tableColumn.minLiveScore": "Minimum activity",
    "tableColumn.taskDays": "Task days",
    "tableColumn.canGetTimes": "Received limit",
    "tableColumn.canGetTimesRemark": " (0 means unlimited)",
    "tableColumn.sortNum": "Sort Number",
    "tableColumn.terms_and_conditions": "Terms and Conditions",
    "tableColumn.mini_amount": "Min Amount",
    "tableColumn.amount_list": "Amount list",
    "tableColumn.agent_level": "Agent level",
    "tableColumn.vipBenefit": "VIP Benefit",
    "tableColumn.vipBenefitEn": "VIP Benefit English",
    "tableColumn.vipBenefitVn": "VIP Benefit Vietnamese",
    "tableColumn.directVipRecommendCount": "Direct Vip Children Count",
    "tableColumn.shopOpen": "Shop open",
    "tableColumn.bean": "Bean",
    "tableColumn.role": "Role",
    "tableColumn.roleName": "Role Name",
    "tableColumn.expire_flash_sale": "Expire Flash Sale",
    "tableColumn.goods": "Goods",
    "tableColumn.shop": "Shop",
    "tableColumn.trade_no": "Trade No",
    "tableColumn.product_name": "Product Name",
    "tableColumn.product_image": "Product Image",
    "tableColumn.goods_price": "Product Price",
    "tableColumn.buy_amount": "Buy Amount",
    "tableColumn.paid_amount": "Paid Amount",
    "tableColumn.delivery_charge": "Delivery fee",
    "tableColumn.total_paid_amount": "Total Paid Amount",
    "tableColumn.reason": "Reason",
    "tableColumn.reason_change": "Reason Change",
    "tableColumn.status": "Status",
    "tableColumn.message": "Message",
    "tableColumn.created_at": "Created Date",
    "tableColumn.updated_at": "Updated Date",
    "tableColumn.key": "Key",
    "tableColumn.value": "Value",
    "tableColumn.social": "Social",
    "tableColumn.finance_term": "Finance Term",
    "tableColumn.return_term_no": "Return Term No",
    "tableColumn.total_interest": "Total Interest",
    "tableColumn.start_time": "Start Time",
    "tableColumn.end_time": "End Time",
    "tableColumn.balance_income_balance": "Balance & Income Balance",
    "tableColumn.score_income_score": "Score & Income Score",
    "tableColumn.vip_sub_users": "VIP sub users",
    "tableColumn.question": "Question",
    "tableColumn.answer_key": "Answer Key",
    "tableColumn.banner": "Banner",
    "tableColumn.area": "Area",
    "tableColumn.tags": "Tags",
    "tableColumn.tag": "Tag",
    "tableColumn.member_id": "Member Id",
    "tableColumn.secret_key": "Secret Key",
    "tableColumn.en": "English",
    "tableColumn.goods_name": "Product Name",
    "tableColumn.goods_name_en": "English Product Name",
    "tableColumn.goods_num": "Quantity",
    "tableColumn.order_status": "Order Status",
    "tableColumn.express_no": "Tracking Number",
    "tableColumn.express_company": "Express Company",
    "tableColumn.service_charge": "Service charge",
    "tableColumn.shopLogo": "Shop logo",
    "tableColumn.shopBanner": "Shop Banner",
    "tableColumn.shopId": "Shop ID",
    "tableColumn.shopCountry": "Shop country",
    "tableColumn.shopMobile": "Shop owner mobile",
    "tableColumn.shopAdress": "Shop address",
    "tableColumn.shopArea": "Shop area",
    "tableColumn.shopStatus": "Shop status",
    "tableColumn.shopTag": "Shop Tag",
    "tableColumn.receiver": "Receiver",
    "tableColumn.receiver_address": "Address Details",
    "tableColumn.receiver_province": "City/province",
    "tableColumn.receiver_district": "District/Sum",
    "tableColumn.receiver_region": "Region",
    "tableColumn.receiver_moible": "Receiver Mobile",
    "tableColumn.shop_share_amount": "Shop Share Amount",
    "tableColumn.used_bean": "Used Bean",
    "tableColumn.shop_name": "Shop Name",
    "tableColumn.reward_score": "Reward Score",
    "tableColumn.total_score_user_can_get": "Total Score User Can Get",
    "tableColumn.total_score_user_claimed": "Total Score User Claimed",
    "tableColumn.total_user_finish_task": "Total User Finish Task",
    "tableColumn.total_user_claimed_score": "Total User Claimed Score",
    "tableColumn.available": "Available",
    "tableColumn.task_days": "Task Days",
    "tableColumn.configuration": "Configuration",
    "tableColumn.watch_video": "Watch Video",
    "tableColumn.answer_question": "Answer Question",
    "tableColumn.news": "News",
    "tableColumn.flash_sale": "Flash Sale",
    "tableColumn.promote_links": "Promote Links",
    "tableColumn.date": "Date",
    "tableColumn.invite_friends": "Invite Friends",
    "tableColumn.default": "Default",
    "tableColumn.answer": "Answer",
    "tableColumn.option": "Option",
    "tableColumn.fact": "Fact",
    "tableColumn.score": "Score",
    "tableColumn.notes": "Notes",
    "tableColumn.visit_flash_sale": "Visit Flash Sale",
    "tableColumn.num_user_can_get": "User can get",
    "tableColumn.expire_time": "Expire Time",
    "tableColumn.release_time": "Release Time",
    "tableColumn.total_sold": "Total Sold",
    "tableColumn.total_earnings": "Total Earnings",
    "tableColumn.product": "Product",
    "tableColumn.sku": "SKU",
    "tableColumn.variations": "Variations",
    "tableColumn.parent_variation": "Parent Variation",
    "tableColumn.original_price": "Original Price",
    "tableColumn.product_en": "English Product ",
    "tableColumn.value_en": "English Value ",
    "tableColumn.code": "Code",
    "tableColumn.code_name": "Code",
    "tableColumn.back_to_select": "Back to Select",
    "tableColumn.add_new": "Add new",
    "tableColumn.react_count": "Reaction Count",
    "tableColumn.charge_type": "Charge Type",
    "tableColumn.video_info": "Video Information",
    "tableColumn.video": "Video",
    "tableColumn.title_en": "English Video",
    "tableColumn.user_received_product": "User has received product",
    "tableColumn.user_come_to_get_product":
        "Does the user come to get the product?",
    "tableColumn.OrderInfo": "Order Information",
    "tableColumn.sub_title": "Sub Title",
    "tableColumn.contractNumber": "Contract No",
    "tableColumn.partyA": "Party A",
    "tableColumn.partyB": "Party B",
    "tableColumn.partyC": "Party C",
    "tableColumn.partyD": "Party D",
    "tableColumn.investmentAmount": "Amount",
    "tableColumn.head": "Head",
    "tableColumn.country": "Country",
    "tableColumn.province": "Province",
    "tableColumn.registerNumber": "Registration Number",
    "tableColumn.email": "Email",
    "tableColumn.accountName": "Account Name",
    "tableColumn.accountNumber": "Account Number",
    "tableColumn.branchName": "Branch Name",
    "tableColumn.signature": "Signature",
    "tableColumn.cycleSelection": "Duration",
    "tableColumn.contractLanguage": "Language",
    "tableColumn.statusContract": "Status",
    "tableColumn.PENDING": "PENDING",
    "tableColumn.SIGNED": "SIGNED",
    "tableColumn.FINISHED": "FINISHED",
    "tableColumn.PAID": "PAID",
    "tableColumn.eContractAcceptAlertTitle":
        "Are you sure you want to accept this contract?",
    "tableColumn.eDeleteContractAlertTitle": "Are you sure you want to delete?",
    "tableColumn.eContractAlertContent": "This action cannot be undone.",
    "tableColumn.chainName": "Chain Name",
    "tableColumn.walletAddress": "Wallet Address",
    "tableColumn.THIRTY_DAYS": "30 days",
    "tableColumn.NINETY_DAYS": "90 days",
    "tableColumn.phoneNumber": "Telephone number",
    "tableColumn.days": "Days",
    "tableColumn.city": "City",
    "tableColumn.paymentStatus": "Payment Status",
    "tableColumn.paymentTime": "Payment Time",
    "tableColumn.paidAmount": "Paid Amount",
    "tableColumn.paymentMethod": "Payment Method",
    "tableColumn.paymentDate": "Payment Date",
    "tableColumn.paymentProof": "Payment Proof",
    "tableColumn.referance": "Referance",
    "tableColumn.club": "Club",
    "tableColumn.contactMethod": "Contact Method",
    "tableColumn.contact": "Contact",
    "tableColumn.point": "Point",
    "tableColumn.user": "User",
    "tableColumn.income": "Income",
    "tableColumn.invest_amount": "Invest Amount",
    "tableColumn.free_fee_withdraw_amount": "Free Fee Withdraw",
    "tableColumn.enable_level_grade": "Enable Level Grade",
    "tableColumn.valid": "Valid",
    "tableColumn.is_club_owner": "Is Club Owner",
    "tableColumn.parent": "Parent",
    "tableColumn.commission_rate": "Commission Rate",
    "tableColumn.direct_valid_member_count": "Direct Valid Member Count",
    "tableColumn.team_invest_amount": "Team Invest Amount",
    "tableColumn.agent_count": "Agent Count",
    "tableColumn.from_level": "From Level",
    "tableColumn.to_level": "To Level",
    "tableColumn.recharge_type": "Recharge Type",
    "tableColumn.rate": "Rate",
    "tableColumn.biz": "Biz",
    "tableColumn.order": "Order",
    "tableColumn.company": "Company",
    "tableColumn.income_rate": "Income Rate",
    "tableColumn.invest_rule": "Invest Rule",
    "tableColumn.contract": "Contract",
    "tableColumn.total_amount": "Total Amount",
    "tableColumn.total_expend": "Total Expend",
    "tableColumn.total_income": "Total Income",
    "tableColumn.third_party_amount": "Third Party Amount",
    "tableColumn.author": "Author",
    "tableColumn.like": "Like",
    "tableColumn.dis_like": "dislike",
    "tableColumn.author_avatar": "Author Avatar",
    "tableColumn.task": "Task",
    "tableColumn.location": "Location",
    "tableColumn.rating": "Rating",
    "tableColumn.passport": "Passport",
    "tableColumn.nationality": "Nationality",
    "tableColumn.birthday": "Birthday",
    "tableColumn.expire_date": "Expire Date",
    "tableColumn.issue_date": "Issue Date",
    "tableColumn.travel": "Travel",
    "tableColumn.sort_num": "Sort Num",
    "tableColumn.recommend_for_home": "Recommend For Home",
    "tableColumn.recommend_free_travel": "Recommend Free Travel",
    "tableColumn.recommend_explore": "Recommend Explore",
    "tableColumn.home": "Home",
    "tableColumn.free_travel": "Free Travel",
    "tableColumn.explore": "Explore",
    "tableColumn.reward_ratio": "Reward Ratio",
    "tableColumn.learn_seconds": "Learn Seconds",
    "tableColumn.start_date": "Start Date",
    "tableColumn.travel_duration": "Travel Duration",
    "tableColumn.travel_destination": "Travel Destination",
    "tableColumn.min_amount": "Min Amount",
    "tableColumn.max_amount": "Max Amount",
    "tableColumn.day_and_rate": "Day and Income Rate",
    "tableColumn.point_rate": "Point Rate",
    "tableColumn.travel_overview": "Travel Overview",
    "tableColumn.travel_information": "Travel Information",
    "tableColumn.min_invest": "Min Invest",
    "tableColumn.min_deposit": "Min Deposit",
    "tableColumn.task_type": "Task Type",
    "tableColumn.isInvest": "Is Invest",
    "tableColumn.established_date": "Established Date",
    "tableColumn.post_or_video": "Post or Video",
    "tableColumn.post": "Post",
    "tableColumn.price_included": "Price Included",
    "tableColumn.price_excluded": "Price Excluded",
    "tableColumn.registration_date": "Registration Date",
    "tableColumn.minimum_participants": "Minimum Participants",
    "tableColumn.invest_banner_article": "Article Cover",
    "tableColumn.invest_article": "Article",
    "tableColumn.coordinate": "Coordinate",
    "tableColumn.total_investment": "Total Investment",
    "tableColumn.lead_amount": "Lead Amount",
    "tableColumn.follow_up_amount": "Follow Up Amount",
    "tableColumn.dining": "Dining",
    "tableColumn.accommodation": "Accommodation",
    "tableColumn.banner_article": "Article Cover",
    "tableColumn.content_article": "Article",
    "tableColumn.club_user_ids": "Club User",
    "tableColumn.traveler": "Traveler",
    "tableColumn.travel_time": "Travel Time",
    "tableColumn.invite_code": "Invite Code",
    "tableColumn.total_assets": "Total Assets",
    "tableColumn.fund": "Fund",
    "tableColumn.note": "Note",
    "tableColumn.customer_id": "Customer ID",
    "tableColumn.club_id": "Club ID",
    "tableColumn.biz_type": "Biz Type",
    "tableColumn.account_type": "Account Type",
    "tableColumn.chain_type": "Chain Type",
    "tableColumn.passenger": "Passenger",
    "tableColumn.background_image": "Background Image",
    "tableColumn.currency_type": "Currency",
    "tableColumn.recharge_to_usdt_rate": "Rate",
    "tableColumn.deposit_amount": "Deposit Amount",
    "tableColumn.bank": "Bank",
    "tableColumn.usdt_amount": "USDT Amount",
    "tableColumn.deposit_image": "Deposit Image",
    "tableColumn.2fa": "Code 2FA",
    "tableColumn.service_charge_fee": "Fee",
    "tableColumn.audit_time": "Audit Date",
    "tableColumn.search": "Search",
    "tableColumn.gender": "Gender",
    "tableColumn.reject_reason": "Reject Reason",
    "tableColumn.booking_id": "Booking Id",
    "tableColumn.totalUSDTSuccess": "Total Success USDT Recharge",
    "tableColumn.totalRechargeAmount": "Total Success Recharge Amount",
    "tableColumn.totalUSDTAuditing": "Total Waiting USDT Recharge",
    "tableColumn.totalWithdrawAuditing": "Total Waiting Withdraw",
    "tableColumn.totalWithdrawAuditPass": "Total Audit Pass Withdraw",
    "tableColumn.totalWithdrawPaid": "Total Paid Withdraw",
    "tableColumn.country_code": "Country Code",
    "tableColumn.country_name": "Country Name",
    "tableColumn.bank_card": "Bank Card",
    "tableColumn.symbol": "Currency Symbol",
    "tableColumn.currency_code": "Currency Code",
    "tableColumn.basic_like": "Increase the number of likes",
    "tableColumn.total_like": "Total the number of likes",
    "tableColumn.wallet_address": "Wallet Address",
    "tableColumn.recharge_origin_amount": "Origin Amount",
    "tableColumn.cash_deposit_rules": "Rules",
    "tableColumn.cash_deposit_rule_desc": "Rules Description",
    "tableColumn.update_rule_cash_deposit": "Update Rule Cash Deposit",
    "tableColumn.company_name": "Company Name",
    "tableColumn.usdt_address": "USDT Address",
    "tableColumn.amount_apply": "Amount Apply",
    "tableColumn.balance_fee": "Balance fee",
    "tableColumn.point_fee": "Point fee",
    "tableColumn.is_internal": "Staff account",
    "tableColumn.parent_id": "Parent Id",
    "tableColumn.copied": "Copied",
    "tableColumn.copy_fail": "Copy fail",
    "tableColumn.popup": "Popup",
    "tableColumn.marquee": "Marquee",
    "tableColumn.vod_id": "ID",
    "tableColumn.type_id": "Type ID",
    "tableColumn.vod_name": "Name",
    "tableColumn.vod_sub": "Sub",
    "tableColumn.vod_en": "English Name",
    "tableColumn.vod_status": "Status",
    "tableColumn.vod_letter": "Letter",
    "tableColumn.vod_tag": "Tag",
    "tableColumn.vod_class": "Class",
    "tableColumn.vod_pic": "Pic",
    "tableColumn.vod_actor": "Actor",
    "tableColumn.vod_director": "Director",
    "tableColumn.vod_writer": "Writer",
    "tableColumn.vod_blurb": "Blurb",
    "tableColumn.vod_remarks": "Remarks",
    "tableColumn.vod_pubdate": "Pubdate",
    "tableColumn.vod_total": "Total",
    "tableColumn.vod_serial": "Serial",
    "tableColumn.vod_area": "Area",
    "tableColumn.vod_lang": "Lang",
    "tableColumn.vod_version": "Version",
    "tableColumn.vod_state": "State",
    "tableColumn.vod_author": "Author",
    "tableColumn.vod_duration": "Duration",
    "tableColumn.vod_time_hits": "Time Hits",
    "tableColumn.vod_time_make": "Time Make",
    "tableColumn.vod_trysee": "Trysee",
    "tableColumn.vod_content": "Content",
    "tableColumn.vod_play_from": "Play From",
    "tableColumn.vod_play_url": "Play Url",
    "tableColumn.type_name": "Type Name",
    "tableColumn.vod_time_str": "Time",
    "tableColumn.meal": "Meal",
    "tableColumn.dishes": "Dishes",
    "tableColumn.chsName": "Chinese name",
    "tableColumn.engName": "English name",
    "tableColumn.khmerName": "Khmer Name",
    "tableColumn.lowRating": "Bad review",
    "tableColumn.mediumRating": "Medium review",
    "tableColumn.highRating": "Good review",
    "tableColumn.searchName": "Search By Name",
    "tableColumn.urgencyLevel": "Urgency level",
    "tableColumn.feedback": "Feedback content",
    "tableColumn.mark": "memo",
    "tableColumn.time": "time",
    "tableColumn.pending": "pending",
    "tableColumn.read": "read",
    "tableColumn.processing": "processing",
    "tableColumn.solved": "solved",
    "tableColumn.feedbackUrgent": "urgent",
    "tableColumn.feedbackNormal": "normal",
    "tableColumn.feedbackSlow": "slow",
    "tableColumn.ip": "ip",
    "tableColumn.minFeedbackNum": "feedback min character",
    "tableColumn.breakfastRange": "Breakfast time range",
    "tableColumn.lunchRange": "Lunch time range",
    "tableColumn.dinnerRange": "Dinner time range",
    "tableColumn.midnightsnackRange": "Midnight snack time range",
    "tableColumn.China": "China Dishes",
    "tableColumn.Cambodia": "Cambodia Dishes",
    // start Translation
    "tableColumn.env": "Environment",
    "tableColumn.room": "Room",
    "tableColumn.food": "Food",
    "tableColumn.bar": "Bar",
    "tableColumn.entertainment": "Entertainment",
    "tableColumn.service": "Service",
    "tableColumn.features": "Features",
    "tableColumn.service_title": "Service Title",
    "tableColumn.service_sub_title": "Service Sub Title",
    "tableColumn.service_desc": "Service Description",
    "tableColumn.service_efficiency": "Service Efficiency",
    "tableColumn.service_satisfaction": "Service Satisfaction",
    "tableColumn.service_user_choice": "User Choice",
    "tableColumn.phone": "Phone",
    "tableColumn.telegram": "Telegram",
    "tableColumn.roomPhone": "Room Phone",
    "tableColumn.roomTg": "Room Telegram",
    "tableColumn.foodPhone": "Food Phone",
    "tableColumn.foodTg": "Food Telegram",
    "tableColumn.name_zh": "Chinese Name",
    "tableColumn.name_km": "Khmer Name",
    "tableColumn.description_zh": "Chinese Description",
    "tableColumn.description_km": "Khmer Description",
    "tableColumn.checkInTime": "Check In Time",
    "tableColumn.checkOutTime": "Check Out Time",
    "tableColumn.facility": "Facility",
    "tableColumn.basicInfo": "Basic Info",
    "tableColumn.bed": "Bed Type",
    "tableColumn.addBedInfo": "Additional Bed Info",
    "tableColumn.basic": "Basic",
    "tableColumn.bathroom": "Bathroom",
    "tableColumn.sleep": "Sleep",
    "tableColumn.device": "Device",
    "tableColumn.zh": "Chinese",
    "tableColumn.km": "Khmer",
    "tableColumn.corner": "Tag",
    "tableColumn.corner_en": "Tag",
    "tableColumn.corner_zh": "Chinese Tag",
    "tableColumn.corner_km": "Khmer Tag",
    "tableColumn.t": "Type",
};
