import dataOverview from "./km-KM/dataOverview";
import common from "./km-KM/common";
import tableColumn from "./km-KM/tableColumn";
import modal from "./km-KM/modal";
import validation from "./km-KM/validation";
import status from "./km-KM/status";
import label from "./km-KM/label";

export default {
    dataOverview,
    common,
    tableColumn,
    modal,
    validation,
    status,
    label,
};
