import {makeContext, useDraw, UseRouterReturnType} from '@qsh/base'
import {useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'

const samePrefixLen = (str1: string, str2: string) => {
  let len = 0
  for (let i = 0; i < str1.length; i++) {
    if (str1[i] === str2[i]) {
      len++
    } else {
      break
    }
  }
  return len
}


export type MenuInfo = {
  id: number;
  pid: number;
  name: string;
  path: string;
  icon: string;
  sort_num: number;
  status: string;
}

export const [AppProvider, useAppContext] = makeContext(() => {
  const appRef = useRef<{ requestMenu?: () => Promise<MenuInfo[]> }>({})
  const [collapsed, setCollapsed] = useState(false)
  const {pathname} = useLocation()
  const [menus, setMenus] = useState<MenuInfo[]>([])
  const menuMap = menus.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {} as any)

  const refreshMenus = (callback?: (data: MenuInfo[]) => void) => {
      appRef.current.requestMenu?.().then((menu) => {
        // console.log(menu);
        
        setMenus(menu)
        callback?.(menu)
      }).catch ( (error) => {
        console.log('error ==== ', error)
      }) 
  }

  const findCurrentMenu = () => {
    let currentMenu
    let currentLen = 0
    // 先找一个最大前缀为当前菜单
    for (let i = 0; i < menus.length; i++) {
      let menu = menus[i]
      const prefixLen = menu.path ? samePrefixLen(menu.path, pathname) : 0
      if (!currentMenu) {
        currentMenu = menu
        currentLen = prefixLen
        continue
      }
      if (prefixLen > currentLen) {
        currentMenu = menu
        currentLen = prefixLen
      }
    }
    return currentMenu
  }

  const findMenuPath = (menu: any) => {
    let path = []
    while (menu) {
      path.unshift(menu)
      menu = menuMap[menu.pid]
    }
    return path
  }

  const menuPath = findMenuPath(findCurrentMenu())

  useEffect(() => {
    refreshMenus()
  }, [])

  return {
    menus,
    setMenus,
    menuMap,
    collapsed,
    setCollapsed,
    menuPath,
    refreshMenus,
    appRef,
  }
})

let currentRouter: UseRouterReturnType

export const getCurrentRouter = () => {
  return currentRouter
}

export const setCurrentRouter = (r: UseRouterReturnType) => {
  currentRouter = r
}
