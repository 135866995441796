export default {
    'wait_review': '等待审核',
    'review_fail': '审核失败',
    'review_scc': '审核成功',
    'wait_return': '等待返回',
    'return_fail': '返回失败',
    'return_succ': '返回成功',
    'wait_refund': '等待退款',
    'refund_fail': '退款失败',
    'refund_succ': '退款成功',
    'refund': '退款',
    'pending': '待审核',
    'checked': '已检查',
    'responded': '已回复',
    'feedback': '反馈',
    'complain': '抱怨',
    'good_review': '好评',
    'about_app': '关于应用程序',
    'running': '跑步',
    'finished': '完成的',
    'app': 'APP',
    'shipping': '船运',
    "orderRebate": "返利进行中",
    "orderSuccess": "处理完成",
    "orderWaitDelivery": "等待发货",
    "orderWaitUserPicked": "等待用户前来取货",
    "orderWaitReceived": "等待接收",
    "orderRefund": "退款",
    "confirmed": "通过",
    "rejected": "拒绝",
}