import React from "react"
import {Breadcrumb} from 'antd'
import {useAppContext} from "./hooks"

export const CPageContainer = ({children}: any) => {
  let {menuPath} = useAppContext()
  return (
    <div>
      <div className="p16 bg-white border-t">
        <Breadcrumb>
          {menuPath?.map((item:any) => {
            return <Breadcrumb.Item key={item.id}>{item.name}</Breadcrumb.Item>
          })}
        </Breadcrumb>
      </div>
      <div className="p24">
        {children}
      </div>
    </div>
  )
}
