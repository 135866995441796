// Generated by api.js
// DO NOT EDIT

import {createApi} from './request'

export const adminImageListApi = createApi<AdminImageListRequest,AdminImageListResponse>('/admin/admin_image/list')
export const authCaptchaApi = createApi<AuthCaptchaRequest,AuthCaptchaResponse>('/admin/auth/captcha')
export const authLoginApi = createApi<AuthLoginRequest,AuthLoginResponse>('/admin/auth/login')
export const authMenusApi = createApi<AuthMenusRequest,AuthMenusResponse>('/admin/auth/menus')
export const centerApiAllApi = createApi<CenterApiAllRequest,CenterApiAllResponse>('/admin/center/api/all')
export const centerApiCreateApi = createApi<CenterApiCreateRequest,CenterApiCreateResponse>('/admin/center/api/create')
export const centerApiListApi = createApi<CenterApiListRequest,CenterApiListResponse>('/admin/center/api/list')
export const centerApiSwitchStatusApi = createApi<CenterApiSwitchStatusRequest,CenterApiSwitchStatusResponse>('/admin/center/api/switch/status')
export const centerApiUpdateApi = createApi<CenterApiUpdateRequest,CenterApiUpdateResponse>('/admin/center/api/update')
export const centerMenuAllApi = createApi<CenterMenuAllRequest,CenterMenuAllResponse>('/admin/center/menu/all')
export const centerMenuBindApiAllApi = createApi<CenterMenuBindApiAllRequest,CenterMenuBindApiAllResponse>('/admin/center/menu/bind/api/all')
export const centerMenuBindApiCreateApi = createApi<CenterMenuBindApiCreateRequest,CenterMenuBindApiCreateResponse>('/admin/center/menu/bind/api/create')
export const centerMenuBindApiDeleteApi = createApi<CenterMenuBindApiDeleteRequest,CenterMenuBindApiDeleteResponse>('/admin/center/menu/bind/api/delete')
export const centerMenuBindApiListApi = createApi<CenterMenuBindApiListRequest,CenterMenuBindApiListResponse>('/admin/center/menu/bind/api/list')
export const centerMenuCreateApi = createApi<CenterMenuCreateRequest,CenterMenuCreateResponse>('/admin/center/menu/create')
export const centerMenuDetailApi = createApi<CenterMenuDetailRequest,CenterMenuDetailResponse>('/admin/center/menu/detail')
export const centerMenuSwitchStatusApi = createApi<CenterMenuSwitchStatusRequest,CenterMenuSwitchStatusResponse>('/admin/center/menu/switch/status')
export const centerMenuUpdateApi = createApi<CenterMenuUpdateRequest,CenterMenuUpdateResponse>('/admin/center/menu/update')
export const centerRoleAllApi = createApi<CenterRoleAllRequest,CenterRoleAllResponse>('/admin/center/role/all')
export const centerRoleBindApiListApi = createApi<CenterRoleBindApiListRequest,CenterRoleBindApiListResponse>('/admin/center/role/bind/api/list')
export const centerRoleBindApiUpdateApi = createApi<CenterRoleBindApiUpdateRequest,CenterRoleBindApiUpdateResponse>('/admin/center/role/bind/api/update')
export const centerRoleBindMenuListApi = createApi<CenterRoleBindMenuListRequest,CenterRoleBindMenuListResponse>('/admin/center/role/bind/menu/list')
export const centerRoleBindMenuUpdateApi = createApi<CenterRoleBindMenuUpdateRequest,CenterRoleBindMenuUpdateResponse>('/admin/center/role/bind/menu/update')
export const centerRoleCreateApi = createApi<CenterRoleCreateRequest,CenterRoleCreateResponse>('/admin/center/role/create')
export const centerRoleListApi = createApi<CenterRoleListRequest,CenterRoleListResponse>('/admin/center/role/list')
export const centerRoleSwitchStatusApi = createApi<CenterRoleSwitchStatusRequest,CenterRoleSwitchStatusResponse>('/admin/center/role/switch/status')
export const centerRoleUpdateApi = createApi<CenterRoleUpdateRequest,CenterRoleUpdateResponse>('/admin/center/role/update')
export const centerUserCreateApi = createApi<CenterUserCreateRequest,CenterUserCreateResponse>('/admin/center/user/create')
export const centerUserListApi = createApi<CenterUserListRequest,CenterUserListResponse>('/admin/center/user/list')
export const centerUserModifyPasswordApi = createApi<CenterUserModifyPasswordRequest,CenterUserModifyPasswordResponse>('/admin/center/user/modify/password')
export const centerUserRoleBindApi = createApi<CenterUserRoleBindRequest,CenterUserRoleBindResponse>('/admin/center/user/role/bind')
export const centerUserRoleListApi = createApi<CenterUserRoleListRequest,CenterUserRoleListResponse>('/admin/center/user/role/list')
export const centerUserRoleUnbindApi = createApi<CenterUserRoleUnbindRequest,CenterUserRoleUnbindResponse>('/admin/center/user/role/unbind')
export const centerUserSwitchStatusApi = createApi<CenterUserSwitchStatusRequest,CenterUserSwitchStatusResponse>('/admin/center/user/switch/status')
export const centerUserUpdateApi = createApi<CenterUserUpdateRequest,CenterUserUpdateResponse>('/admin/center/user/update')
export const companyCreateApi = createApi<CompanyCreateRequest,CompanyCreateResponse>('/admin/company/create')
export const companyListApi = createApi<CompanyListRequest,CompanyListResponse>('/admin/company/list')
export const companyUpdateApi = createApi<CompanyUpdateRequest,CompanyUpdateResponse>('/admin/company/update')
export const companyUpdateStatusApi = createApi<CompanyUpdateStatusRequest,CompanyUpdateStatusResponse>('/admin/company/update_status')
export const dashboardReportApi = createApi<DashboardReportRequest,DashboardReportResponse>('/admin/dashboard/report')
export const dashboardSummaryApi = createApi<DashboardSummaryRequest,DashboardSummaryResponse>('/admin/dashboard/summary')

export type AdminImageListItem = {
  http_url: string;
  id: number;
  name: string;
}

export type AdminImageListRequest = {
  // 名字
  name: string;
  // 页码
  page: number;
}

export type AdminImageListResponse = {
  items: AdminImageListItem[];
  // 总数
  total: number;
}

export type AuthCaptchaRequest = {

}

export type AuthCaptchaResponse = {
  // 验证码ID
  captcha_id: string;
  // 验证码图片
  captcha_image: string;
}

export type AuthLoginRequest = {
  // 验证码ID
  captcha_id: string;
  // 密码
  password: string;
  // 用户名
  username: string;
  // 验证码
  verify_code: string;
  // deviceID
  deviceID: string;
}

export type AuthLoginResponse = {
  // 昵称
  nickname: string;
  // token
  token: string;
  // 用户id
  user_id: string;
  // 用户名
  username: string;
}

export type AuthMenusRequest = {

}

export type AuthMenusResponse = {
  items: CenterMenuInfo[];
}

export type AuthUser = {
  id: number;
  user_id: string;
}

export type CenterApiAllItem = {
  id: number;
  // api名称
  name: string;
  // api路径
  path: string;
}

export type CenterApiAllRequest = {
  // 状态
  status: string;
}

export type CenterApiAllResponse = {
  items: CenterApiAllItem[];
}

export type CenterApiCreateRequest = {
  // api名称
  name: string;
  // api路径
  path: string;
}

export type CenterApiCreateResponse = {

}

export type CenterApiInfo = {
  // 创建时间
  created_at: string;
  id: number;
  // api名称
  name: string;
  // api路径
  path: string;
  // 状态
  status: string;
}

export type CenterApiListRequest = {
  // api名称
  name: string;
  page: number;
}

export type CenterApiListResponse = {
  items: CenterApiInfo[];
  total: number;
}

export type CenterApiSwitchStatusRequest = {
  id: number;
  status: string;
}

export type CenterApiSwitchStatusResponse = {

}

export type CenterApiUpdateRequest = {
  id: number;
  // api名称
  name: string;
  // api路径
  path: string;
}

export type CenterApiUpdateResponse = {

}

export type CenterMenuAllRequest = {

}

export type CenterMenuAllResponse = {
  items: CenterMenuInfo[];
}

export type CenterMenuBindApiAllRequest = {

}

export type CenterMenuBindApiAllResponse = {
  items: CenterMenuBindApiInfo[];
}

export type CenterMenuBindApiCreateRequest = {
  api_ids: number[];
  menu_id: number;
}

export type CenterMenuBindApiCreateResponse = {

}

export type CenterMenuBindApiDeleteRequest = {
  api_id: number;
  menu_id: number;
}

export type CenterMenuBindApiDeleteResponse = {

}

export type CenterMenuBindApiInfo = {
  api_id: number;
  create_at: string;
  id: number;
  menu_id: number;
  name: string;
  path: string;
}

export type CenterMenuBindApiListRequest = {
  menu_id: number;
}

export type CenterMenuBindApiListResponse = {
  items: CenterMenuBindApiInfo[];
}

export type CenterMenuCreateRequest = {
  app_id: string;
  icon: string;
  name_json: modelLang;
  path: string;
  pid: number;
  sort_num: number;
}

export type CenterMenuCreateResponse = {

}

export type CenterMenuDetailRequest = {
  id: number;
}

export type CenterMenuDetailResponse = {
  created_at: string;
  icon: string;
  id: number;
  name: string;
  name_json: modelLang;
  path: string;
  pid: number;
  sort_num: number;
  status: string;
  updated_at: string;
}

export type CenterMenuInfo = {
  created_at: string;
  icon: string;
  id: number;
  name: string;
  name_json: modelLang;
  path: string;
  pid: number;
  sort_num: number;
  status: string;
  updated_at: string;
}

export type CenterMenuSwitchStatusRequest = {
  id: number;
  status: string;
}

export type CenterMenuSwitchStatusResponse = {

}

export type CenterMenuUpdateRequest = {
  icon: string;
  id: number;
  name_json: modelLang;
  path: string;
  pid: number;
  sort_num: number;
}

export type CenterMenuUpdateResponse = {

}

export type CenterRoleAllItem = {
  id: number;
  // 角色名称
  name: string;
}

export type CenterRoleAllRequest = {

}

export type CenterRoleAllResponse = {
  items: CenterRoleAllItem[];
}

export type CenterRoleBindApiListRequest = {
  // 角色id
  role_id: number;
}

export type CenterRoleBindApiListResponse = {
  api_id_list: number[];
}

export type CenterRoleBindApiUpdateRequest = {
  api_id_list: number[];
  // 角色id
  role_id: number;
}

export type CenterRoleBindApiUpdateResponse = {

}

export type CenterRoleBindMenuListRequest = {
  // 角色id
  role_id: number;
}

export type CenterRoleBindMenuListResponse = {
  menu_id_list: number[];
}

export type CenterRoleBindMenuUpdateRequest = {
  menu_id_list: number[];
  // 角色id
  role_id: number;
}

export type CenterRoleBindMenuUpdateResponse = {

}

export type CenterRoleCreateRequest = {
  // 角色名称
  name: string;
}

export type CenterRoleCreateResponse = {

}

export type CenterRoleInfo = {
  // 创建时间
  created_at: string;
  id: number;
  // 是否为超级管理员
  is_super: string;
  // 角色名称
  name: string;
  // 状态
  status: string;
}

export type CenterRoleListRequest = {
  page: number;
}

export type CenterRoleListResponse = {
  items: CenterRoleInfo[];
  total: number;
}

export type CenterRoleSwitchStatusRequest = {
  id: number;
  // 状态
  status: string;
}

export type CenterRoleSwitchStatusResponse = {

}

export type CenterRoleUpdateRequest = {
  id: number;
  // 角色名称
  name: string;
}

export type CenterRoleUpdateResponse = {

}

export type CenterUserCreateRequest = {
  nickname: string;
  password: string;
  remark: string;
  user_type: string;
  username: string;
}

export type CenterUserCreateResponse = {

}

export type CenterUserListRequest = {
  page: number;
  user_id: string;
  username: string;
}

export type CenterUserListResponse = {
  items: UserInfo[];
  total: number;
}

export type CenterUserModifyPasswordRequest = {
  id: number;
  password: string;
}

export type CenterUserModifyPasswordResponse = {

}

export type CenterUserRole = {
  // 创建时间
  created_at: string;
  // 角色ID
  id: number;
  // 是否未超级管理员
  is_super: string;
  // 角色名称
  name: string;
  // 角色状态
  status: string;
}

export type CenterUserRoleBindRequest = {
  role_id: number;
  user_id: string;
}

export type CenterUserRoleBindResponse = {

}

export type CenterUserRoleListRequest = {
  user_id: string;
}

export type CenterUserRoleListResponse = {
  items: CenterUserRole[];
}

export type CenterUserRoleUnbindRequest = {
  role_id: number;
  user_id: string;
}

export type CenterUserRoleUnbindResponse = {

}

export type CenterUserSwitchStatusRequest = {
  id: number;
  status: string;
}

export type CenterUserSwitchStatusResponse = {

}

export type CenterUserUpdateRequest = {
  id: number;
  nickname: string;
  remark: string;
  user_type: string;
}

export type CenterUserUpdateResponse = {

}

export type CompanyCreateRequest = {
  description: modelLang;
  // 成立日期
  established_date: string;
  location: modelLang;
  // logo
  logo: string;
  name: modelLang;
  // 排序
  sort_num: number;
}

export type CompanyCreateResponse = {

}

export type CompanyListItem = {
  // 公司id
  company_id: number;
  // 创建时间
  created_at: string;
  description: modelLang;
  // 成立日期
  established_date: string;
  location: modelLang;
  // logo
  logo: string;
  name: modelLang;
  // 排序
  sort_num: number;
  // 状态
  status: string;
  // 更新时间
  updated_at: string;
}

export type CompanyListRequest = {
  // 名称
  name: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
  // 状态 ON启用 OFF禁用
  status: string;
}

export type CompanyListResponse = {
  items: CompanyListItem[];
  // 总数
  total: number;
}

export type CompanyUpdateRequest = {
  // 公司id
  company_id: number;
  description: modelLang;
  // 成立日期
  established_date: string;
  location: modelLang;
  // logo
  logo: string;
  name: modelLang;
  // 排序
  sort_num: number;
}

export type CompanyUpdateResponse = {

}

export type CompanyUpdateStatusRequest = {
  // 公司id
  company_id: number;
  // 状态
  status: string;
}

export type CompanyUpdateStatusResponse = {

}

export type DashboardReportRequest = {
  date_range: string[];
  // Days : 7, 15, 30, 60
  days: number;
  // 父级
  parent: number;
  // 父级id
  parent_id: number;
}

export type DashboardReportResponse = {
  // 总投资额
  total_invest_amount: string;
  // 投资金额 30天
  total_invest_contract_30day: string;
  // 投资金额 90天
  total_invest_contract_90day: string;
  // 投资金额 180天
  total_invest_contract_180day: string;
  // 投资金额 270天
  total_invest_contract_270day: string;
  // 投资金额 360天
  total_invest_contract_360day: string;
  // 投资金额 720天
  total_invest_contract_720day: string;
  // 充值金额eur from cash deposit and all currency type
  total_recharge_eur: string;
  // 充值金额jpy from cash deposit and all currency type
  total_recharge_jpy: string;
  // 充值金额krw from cash deposit and all currency type
  total_recharge_krw: string;
  // 充值金额mnt from cash deposit and all currency type
  total_recharge_mnt: string;
  // 充值金额rmb from cash deposit and all currency type
  total_recharge_rmb: string;
  // 充值金额usd from cash deposit and all currency type
  total_recharge_usd: string;
  // 充值金额usdt
  total_recharge_usdt: string;
  // 充值金额vnd from cash deposit and all currency type
  total_recharge_vnd: string;
  // 注册人数
  total_user_register: string;
  // 团队
  total_user_team: string;
  // 总内提现
  total_with_draw_amount: string;
}

export type DashboardSummaryRequest = {

}

export type DashboardSummaryResponse = {

}

export type Empty = {

}

export type ErrorResponse = {
  code: string;
  detail: any;
  message: string;
}

export type UserInfo = {
  created_at: string;
  id: number;
  nickname: string;
  remark: string;
  status: string;
  updated_at: string;
  user_id: string;
  user_type: string;
  username: string;
}

export type int = {

}

export type modelArticleRecommend = {
  // free travel recommend Y/N
  free_travel: string;
  // home recommend Y/N
  home: string;
}

export type modelLang = {
  // english value
  en: string;
  // chinese value
  zh: string;
}

export type modelLangObject = {
  en: object[];
  zh: object[];
}

export type modelLink = {
  // the type of link => ARTICLE_VIDEO_PAGE, ARTICLE_DETAIL_PAGE,DEPOSIT,INVEST,INVITE_FRIEND, HELP_CENTER,TASK,LEARN,MY_TEAM,SERVICE,COMING_SOON
  type: string;
  // the value of link
  value: string;
}

export type modelNewsRecommend = {
  // home recommend Y/N
  home: string;
}

export type modelRecommendAttr = {
  // explore recommend Y/N
  explore: string;
  // home recommend Y/N
  home: string;
}

export type modelTaskConfigAttr = {
  learn_seconds: number;
}
