// webpack hot
// @ts-ignore
if (module.hot) {
    //@ts-ignore
    module.hot.accept();
}
import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import config, { getEnvironment } from "./config";
import Layout from "./layouts";
import "./assets/style/tailwind.css";
import "./global.less";

import {
    createBrowserRouter,
    RouteObject,
    RouterProvider,
} from "react-router-dom";
import { setCurrentAppId } from "@qsh/center/user";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import enUs from "./translations/en-US";
import zhCN from "./translations/zh-CN";
import kmKm from "./translations/km-KM";

setCurrentAppId(config.app_id);

function resolveRoute() {
    //@ts-ignore
    const files = require.context(
        "./pages",
        true,
        /^((?!\/inner\/).)*\.tsx$/,
        "lazy"
    );
    const routers: RouteObject[] = [];
    files.keys().forEach((key: string) => {
        // remove . and .tsx
        const path = key.replace(/^\./, "").replace(/\.tsx?$/, "");

        const Module = lazy(
            () => import(/* webpackChunkName: "[request]" */ `./pages${path}`)
        );
        // remove index suffix
        let routePath = path.replace(/\/index$/, "");
        if (routePath === "") {
            routePath = "/";
        }

        routers.push({
            path: routePath,
            element: (
                <Suspense
                    fallback={<div className="tx-c mt8 grey6">loading...</div>}
                >
                    <Module />
                </Suspense>
            ),
        });
    });

    return [
        {
            path: "/",
            element: <Layout />,
            children: routers,
        },
    ];
}

const router = createBrowserRouter(resolveRoute(), {
    basename: getEnvironment().base,
});
var defaultLanguage = "zh";

if (localStorage.getItem("locale")) {
    defaultLanguage = localStorage.getItem("locale") || "zh";
}

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: defaultLanguage, // language to use
    // lng: 'zh',                              // language to use
    resources: {
        en: {
            ...enUs,
        },
        zh: {
            ...zhCN,
        },
        km: {
            ...kmKm,
        },
    },
});

const root = createRoot(document.getElementById("root"));
root.render(
    <I18nextProvider i18n={i18next}>
        <RouterProvider router={router} />
    </I18nextProvider>
);
