
export const useQuery = () : any => {
  // todo check
  let urlSearchParams = new URLSearchParams(location.search)
  let query: any = {}
  urlSearchParams.forEach((value, key) => {
    query[key] = value
  })
  return query;
}
