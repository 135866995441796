import React from 'react';
import * as Icons from '@ant-design/icons'

export const NamedIcon = ({name}:{name:string}):any => {
  if (!name) {
    return null
  }
  // @ts-ignore
  const Icon = Icons[name]
  return Icon ?  <Icon /> : null
}
