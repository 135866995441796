import { SizeType } from "antd/lib/config-provider/SizeContext";

export const warningButtonConfig = {
    // size: 'small' as SizeType,
    style: {
        backgroundColor: "orange",
        borderColor: "orange",
        color: "white",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

export const infoButtonConfig = {
    // size: 'small' as SizeType,
    style: {
        backgroundColor: "#0dcaf0",
        borderColor: "#0dcaf0",
        color: "white",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

export const darkBlueButtonConfig = {
    // size: 'small' as SizeType,
    style: {
        backgroundColor: "#2471A3",
        borderColor: "#2471A3",
        color: "white",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

export const darkSalmonButtonConfig = {
    // size: 'small' as SizeType,
    style: {
        backgroundColor: "#D47863",
        borderColor: "#D47863",
        color: "white",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

export const editButtonConfig = {
    type: "primary" as "primary",
    // size: 'small' as SizeType,
    style: {
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};
export const randomButtonConfig = {
    // size: 'small' as SizeType,
    style: {
        backgroundColor: "#52c41a",
        borderColor: "#52c41a",
        fontSize: "16px",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

export const randomDefaultButtonConfig = {
    style: {
        backgroundColor: "#52c41a",
        borderColor: "#52c41a",
        color: "white",
    },
};

export const thumbnailStyle = {
    width: "100px",
    height: "100px",
};

export const greyButtonConfig = {
    // size: 'small' as SizeType,
    style: {
        backgroundColor: "#6c757d",
        borderColor: "#6c757d",
        fontSize: "16px",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

export const dangerButtonConfig = {
    type: "primary" as "primary",
    // size: 'small' as SizeType,
    danger: true,
    style: {
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

export const successButtonConfig = {
    type: "primary" as "primary",
    // size: 'small' as SizeType,
    style: {
        backgroundColor: "#27AE60",
        borderColor: "#27AE60",
        fontSize: "16px",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};
