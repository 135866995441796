import React, {ChangeEvent, useEffect, useState} from 'react'
import {Input } from 'antd'
import { ReloadOutlined } from '@ant-design/icons';
import {InputProps} from 'antd/es'

interface CaptchaValue {
  // 验证码
  verify_code: string;
  // 验证码id
  captcha_id: string;
}

interface CaptchaData extends Pick<CaptchaValue, 'captcha_id'> {
  captcha_image: string
}

export interface CaptchaProps extends Omit<InputProps, 'value' | 'onChange'> {
  api: () => Promise<CaptchaData>;

  value?: CaptchaValue;

  onChange?: (value: CaptchaValue) => void;

  refresh?: number;
}

export const Captcha: React.FC<CaptchaProps> = ({value, api, onChange, refresh, ...props}) => {
  const [captcha, setCaptcha] = useState<CaptchaData>()
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  /**
   * 换一张图片
   */
  const handleChange = ({target: {value: targetValue}}: ChangeEvent<{ value: string }>) => {
    onChange({
      verify_code: targetValue,
      captcha_id: captcha?.captcha_id || ''
    })
  }

  const handleValue = () => {

    if (!api) {
      console.error('api is required')
      return
    }
    setIsLoading(true); // Set isLoading to true before making the API call

    api().then(result => {
      setCaptcha(result)
      if (value?.captcha_id) {
        onChange({...value, captcha_id: result.captcha_id})
      }
    }).catch(err => console.error(err))
    .finally(() => {
      if (value && value.verify_code) {
        onChange({ ...value, verify_code: '' });
      }
      
      setIsLoading(false); // Set isLoading to false when the API call is finished
    });
  }

  useEffect(() => {
    handleValue()
  }, [refresh])

  return (
    <div style={{display:'flex', lineHeight: '40px'}}>
      <Input
        size="large"
        onChange={handleChange}
        value={value?.verify_code}
        placeholder='验证码'
        {...props}
        style={{marginRight: '10px', width: '40%'}}
      />
      <img style={{visibility:captcha?'visible':'hidden', display:'inline-block' , maxWidth: '80px', height: 'auto', cursor:'pointer'}}
           src={captcha?.captcha_image} alt="验证码" title={'验证码'} onClick={handleValue}/>
      <ReloadOutlined 
        onClick={handleValue}
        spin={isLoading}
        style={{
          cursor: 'pointer',
          fontSize: '1.5em',
          height: '100%',
          marginLeft: '10px',
          marginTop: '10px'
        }}
      />
    </div>
  )
}

