import dataOverview from './en-US/dataOverview';
import common from './en-US/common';
import tableColumn from './en-US/tableColumn';
import modal from './en-US/modal';
import validation from './en-US/validation';
import label from './en-US/label';
import status from './en-US/status';

export default {
  dataOverview,
  common,
  tableColumn,
  modal,
  validation,
  label,
  status
};
