type UserType = {
  token: string;
  // user_id: string;
  // username: string;
  // 昵称
  nickname: string;
  [key: string]: any;
}

let state = {
  app_id: '',
}

export const getCurrentState = () => {
  return state
}

export const setCurrentAppId = (app_id: string) => {
  state.app_id = app_id
}

function getKey() {
  return `user:${state.app_id}`
}

export const setCurrentUser = (user: UserType) => {
  localStorage.setItem(getKey(), JSON.stringify(user))
}

export const updateCurrentUser = (user: Partial<UserType>) => {
  const currentUser = getCurrentUser()
  localStorage.setItem(getKey(), JSON.stringify({...currentUser,...user}))
}

export const setAppUser = (app_id: string,user: UserType) => {
  localStorage.setItem(`user:${app_id}`, JSON.stringify(user))
}

export const clearCurrentUser = () => {
  localStorage.removeItem(getKey())
}

export const getCurrentUser = (): UserType | undefined => {
  const user = localStorage.getItem(getKey())
  if (user) {
    return JSON.parse(user)
  }
  return undefined
}
