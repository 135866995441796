type Config = {
    app_id: string;
    environments: {
        [key: string]: {
            base: string;
            gateway: string;
            cdn?: string;
        };
    };
};

const config: Config = {
    app_id: "admin",
    environments: {
        dev: {
            gateway: "/api",
            base: "/",
            cdn: "http://127.0.0.1:10011/getFile?path=",
        },
        test: {
            gateway: "http://154.82.113.141:10006",
            base: "/",
            cdn: "http://154.82.113.141:10006/getFile?path=",
        },
        prod: {
            gateway: "https://adminapi.fungyuethotel.com",
            base: "/",
            cdn: "https://adminapi.fungyuethotel.com/getFile?path=",
        },
    },
};

export const getEnvironment = () => {
    //@ts-ignore
    return config.environments[process.env.APP_ENV];
};

export default config;
