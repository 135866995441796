import dataOverview from "./zh-CN/dataOverview";
import common from "./zh-CN/common";
import tableColumn from "./zh-CN/tableColumn";
import modal from "./zh-CN/modal";
import validation from "./zh-CN/validation";
import status from "./zh-CN/status";
import label from "./zh-CN/label";

export default {
  dataOverview,
  common,
  tableColumn,
  modal,
  validation,
  status,
  label,
};
