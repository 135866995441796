import {createElement} from "react"


// chain
export const createPage = <T,>(...child: [...any[],T]):T =>
  //@ts-ignore
  ({children,...other}:any) => {
  return child.reduceRight((prev, curr) => {
    return createElement(curr, other, prev)
  }, children)
}
